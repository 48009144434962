
import Vue from 'vue';
import { Product, VForm, CancelProductInformation } from '@/types';

export default Vue.extend({
  name: 'CancelProductModal',
  props: {
    visible: {
      type: Boolean as () => boolean,
      required: false,
      default: false,
    },
    successfulRequest: {
      type: Boolean as () => boolean,
      required: false,
      default: false,
    },
    product: {
      type: Object as () => Product,
      required: true,
    },
  },
  data: () => ({
    payload: {
      rating: '',
      comment: '',
    },
  }),
  computed: {
    displayModal: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean) {
        this.$emit('update:visible', value);
      },
    },
  },
  methods: {
    async confirm(): Promise<void> {
      const form: any = this.$refs.form as VForm;
      const valid: boolean = await form.validate();
      if (valid) {
        const { proprietario, modulo } = this.product;
        this.$emit('cancel-product', {
          ownerId: proprietario,
          module: modulo,
          ...this.payload,
        } as CancelProductInformation);
      }
    },
  },
});
