import axios from '@/common/http';
import { CompanyPreferences } from '@/types';

class CompanyPreferencesService {
    private service: typeof axios = axios;

    private rebornBaseUrl = `${process.env.VUE_APP_REBORN_BASE_URL_GATEWAY}/api/v1`;

    public async createUpdateCompanyPreferences(data: CompanyPreferences) {
      return (await this.service.put(`${this.rebornBaseUrl}/company-preferences`, data)).data;
    }

    public async getCompanyPreferences(masterId: string): Promise<CompanyPreferences> {
      return (await this.service.get(`${this.rebornBaseUrl}/company-preferences/master/${masterId}`)).data.data;
    }
}

export default new CompanyPreferencesService();
