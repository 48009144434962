var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.firstAccess
        ? _c("Card", {
            staticClass: "no-products-card",
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "p-d-flex my-4 p-col-12 p-md-4 mx-auto text-center p-ai-center p-flex-column p-jc-center",
                        },
                        [
                          _c("p", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("MyProductsPage.discover.message", {
                                  field: _vm.discoverProduct.title,
                                })
                              ),
                            },
                          }),
                          _c("Button", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("MyProductsPage.discover.btnStart")
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4245641951
            ),
          })
        : _vm.noProducts && !_vm.loading
        ? _c("div", { staticClass: "discover" }, [
            _c(
              "div",
              {
                staticClass:
                  "content p-col-12 p-md-4 h-100 p-md-offset-7 p-d-flex p-flex-wrap p-ai-center text-white text-right text-md-center",
              },
              [
                _c(
                  "div",
                  [
                    _c("p", {
                      staticClass: "d-none d-lg-block",
                      domProps: { innerHTML: _vm._s(_vm.discoverProduct.text) },
                    }),
                    _c(
                      "Button",
                      {
                        class: [
                          "text-uppercase",
                          { "btn-primary": _vm.productName === "bank" },
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.goTo(_vm.discoverProduct.link)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("MyProductsPage.discover.btnDiscover")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "my-product-banner-logo-roit" }, [
              _c("img", {
                attrs: {
                  height: "32",
                  src: require("@/common/assets/images/logo-roit-solutions-white.svg"),
                },
              }),
            ]),
            _c("img", {
              staticClass: "w-100 banner my-product-banner-background",
              attrs: {
                src: require(`@/common/assets/images/${_vm.discoverProduct.image}`),
              },
            }),
          ])
        : _c("custom-table", {
            attrs: {
              "columns-data": _vm.header,
              data: _vm.rows,
              loading: _vm.loading,
            },
            scopedSlots: _vm._u([
              {
                key: "columns",
                fn: function ({ item, column }) {
                  return [
                    column === "modulo"
                      ? _c("td", [_vm._v(" " + _vm._s(item.name) + " ")])
                      : _vm._e(),
                    column === "qtdeContratada"
                      ? _c("td", [_vm._v(" " + _vm._s(item.quantity) + " ")])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "actions",
                fn: function ({ item }) {
                  return [
                    _c("div", { staticClass: "actions" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.topleft",
                              value: _vm.$t(
                                "MyProductsPage.cancelProduct.cancelRequest"
                              ),
                              expression:
                                "$t('MyProductsPage.cancelProduct.cancelRequest')",
                              modifiers: { topleft: true },
                            },
                          ],
                          staticClass: "action",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("cancel-product", item)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/common/assets/icons/cancel-circle.svg"),
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }