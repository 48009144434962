
import Vue from 'vue';
import Tooltip from 'primevue/tooltip';
import EmailModal from './email/EmailModal.vue';

export default Vue.extend({
  name: 'SectionHeader',
  directives: {
    tooltip: Tooltip,
  },
  components: {
    EmailModal,
  },
  props: {
    hideIcon: {
      type: Boolean,
      default: false,
    },
    imageSrc: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      default: '',
    },
    redirectText: {
      type: String,
      default: '',
    },
    emailText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showModal: false as boolean,
    };
  },
  methods: {
    redirectToRoit(): void {
      window.open('https://roit.com.br/', '_blank');
    },
  },
});
