import axios, { CancelTokenSource } from '@/common/http';
import store from '@/store';
import { CancelProductInformation } from '@/types';

class MyProductsService {
    private service: typeof axios = axios;

    private cancelTokenSource: CancelTokenSource | undefined;

    private baseUrl = `${process.env.VUE_APP_BASE_URL_GATEWAY}/api/v1/products`;

    private rebornUrl = `${process.env.VUE_APP_REBORN_BASE_URL_GATEWAY}/api/v1`;

    private cancelToken(): CancelTokenSource {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      return this.service.CancelToken.source();
    }

    public findProducts(search = '') {
      let { baseUrl } = this;

      if (search) {
        baseUrl = baseUrl.concat(`?search=${encodeURIComponent(search)}`);
      }

      this.cancelTokenSource = this.cancelToken();
      return this.service.get(baseUrl, {
        cancelToken: this.cancelTokenSource.token,
      });
    }

    public findGroupedProducts(search = '') {
      let baseUrl = `${this.baseUrl}/groups`;

      if (search) {
        baseUrl = baseUrl.concat(`?search=${encodeURIComponent(search)}`);
      }

      this.cancelTokenSource = this.cancelToken();
      return this.service.get(baseUrl, {
        cancelToken: this.cancelTokenSource.token,
      });
    }

    public deleteProduct(productData: CancelProductInformation) {
      const baseUrl = `${this.baseUrl}/delete`;
      return this.service.post(baseUrl, productData);
    }

    public getDeals() {
      return this.service.get(`${this.rebornUrl}/deals/by-master/${store.state.auth?.userCompany.masterId}`);
    }

    public async getContractLinkByMasterId(): Promise<{linkContract: string}> {
      const response = await this.service.get(`${this.rebornUrl}/deals/contract-link/by-master/${store.state.auth?.userCompany.masterId}`);

      return response.data.data;
    }
}

export default new MyProductsService();
