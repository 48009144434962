
import Vue from 'vue';
import DateFormat from '@/common/filters/DateFormat';
import Table from '@/common/components/table/Table.vue';

export default Vue.extend({
  name: 'ProductTable',
  components: {
    CustomTable: Table,
  },
  filters: {
    dateFormat(value: string) {
      return DateFormat(value, { input: 'YYYY-MM-DD', output: 'DD/MM/YYYY' });
    },
    ucFirst(value?: string): string {
      return value
        ? value?.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
        : '';
    },
  },
  props: {
    rows: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    productName: {
      type: String,
      required: false,
      default: 'people',
    },
    noProducts: {
      type: Boolean,
      required: false,
      default: false,
    },
    firstAccess: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      allItemsSelected: false,
      selectedItems: [] as Array<string>,
      header: {
        alias: [
          this.$t('MyProductsPage.headers.module'),
          this.$t('MyProductsPage.headers.quantity'),
        ],
        columnsToShow: [
          'modulo',
          'qtdeContratada',
        ],
      },
    };
  },
  computed: {
    discoverProduct(): any {
      const products = {
        bank: {
          image: 'my-product-banner-background.jpg' as string,
          title: 'ROIT' as string,
          text: this.$t('MyProductsPage.discover.products.bank') as string,
          link: process.env.VUE_APP_DISCOVER_ROIT_BANK,
        },
      };

      return products[this.productName as keyof typeof products] ?? products.bank;
    },
  },
  methods: {
    goTo(url: string) {
      window.open(url);
    },
  },
});
