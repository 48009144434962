var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "my-products-page" },
    [
      _c("RegisterResponsibleModal", {
        key: `modalKey${_vm.responsible.modalKey}`,
        attrs: { "show-modal": _vm.responsible.showModal },
        on: {
          "update:showModal": function ($event) {
            return _vm.$set(_vm.responsible, "showModal", $event)
          },
          "update:show-modal": function ($event) {
            return _vm.$set(_vm.responsible, "showModal", $event)
          },
          "responsible:add": function ($event) {
            return _vm.addNewResponsible($event)
          },
          "responsible:update": function ($event) {
            return _vm.updateUserAdmin($event)
          },
        },
        model: {
          value: _vm.responsible.payload,
          callback: function ($$v) {
            _vm.$set(_vm.responsible, "payload", $$v)
          },
          expression: "responsible.payload",
        },
      }),
      _c("div", { staticClass: "mt-4" }, [
        _c("div", { staticClass: "grey300" }, [
          _vm._v(" " + _vm._s(_vm.$t("ManagerResponsible.title")) + " "),
        ]),
        _c("div", { staticClass: "grey300" }, [
          _vm._v(" " + _vm._s(_vm.$t("ManagerResponsible.subtitle")) + " "),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "mt-4 d-flex justify-content-between align-items-center",
        },
        [
          _c("Button", {
            staticClass: "p-button-raised",
            attrs: {
              label: _vm.$t("ManagerResponsible.new"),
              icon: "pi pi-user-plus",
              "icon-pos": "left",
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                _vm.responsible.showModal = true
              },
            },
          }),
          _c("div", [
            _c(
              "span",
              { staticClass: "p-input-icon-left" },
              [
                _c("i", { staticClass: "icon-r-magnifier-tool grey100" }),
                _c("InputText", {
                  staticStyle: { height: "40px" },
                  attrs: { type: "text", placeholder: _vm.$t("search") },
                  model: {
                    value: _vm.responsible.search,
                    callback: function ($$v) {
                      _vm.$set(_vm.responsible, "search", $$v)
                    },
                    expression: "responsible.search",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("SectionHeader", {
        staticClass: "mt-4",
        attrs: {
          "hide-icon": "",
          "image-src": require("@/common/assets/images/logo-roit-solutions-blue.svg"),
        },
      }),
      _c("ResponsibleTable", {
        staticClass: "mt-4",
        attrs: {
          rows: _vm.responsible.table.data.bank,
          loading: _vm.responsible.table.loading,
        },
        on: {
          "responsibletable:edit": function ($event) {
            return _vm.editResponsible($event)
          },
          "responsibletable:delete": function ($event) {
            return _vm.deleteResponsible($event)
          },
          "responsibletable:change": function ($event) {
            return _vm.updateUserAdmin($event)
          },
          "responsibletable-products-change": function ($event) {
            return _vm.updateProductsUserAdmin($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }