
import Vue from 'vue';
import CompanyPreferencesService from '@/common/services/CompanyPreferences';
import { mapMutations } from 'vuex';
import { VForm } from '@/types';
import { ProviderInstance } from 'vee-validate/dist/types/types.d';

export default Vue.extend({
  name: 'EmailModalContent',
  data() {
    return {
      input: '' as string,
      emailsAndDomains: [] as Array<string>,
      headerTable: {
        columnsToShow: ['Tipo', 'Endereco'],
        alias: [],
      },
      indexItemEditing: undefined as undefined | number,
    };
  },
  computed: {
    inputEqualEditValue(): boolean {
      if (this.indexItemEditing !== undefined) {
        return this.input === this.emailsAndDomains[this.indexItemEditing];
      }
      return false;
    },
    emailsAndDomainsIncludeInput(): boolean {
      return this.emailsAndDomains.includes(this.input);
    },
  },
  watch: {
    input(): void {
      (this.$refs.form as ProviderInstance).reset();
    },
  },
  async mounted() {
    this.handleLoading(true);

    const { masterId } = this.$store.state.auth.userCompany;

    const response = await CompanyPreferencesService.getCompanyPreferences(
      masterId,
    );

    if (response) {
      this.emailsAndDomains.push(...response.emailInbox);
      this.$emit('update:emailAndDomains', this.emailsAndDomains);
    }

    this.handleLoading(false);
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    async updateEmailOrDomain() {
      const form = this.$refs.form as VForm;
      const isValid = await form.validate();

      if (
        isValid
        && (!this.emailsAndDomainsIncludeInput || this.inputEqualEditValue)
      ) {
        if (this.indexItemEditing !== undefined) {
          this.emailsAndDomains.splice(this.indexItemEditing, 1, this.input);
          this.indexItemEditing = undefined;
          this.input = '';
          return;
        }

        this.emailsAndDomains.push(this.input);
        this.input = '';
      }
      this.$emit('update:emailAndDomains', this.emailsAndDomains);
    },
    editItem(item: string) {
      this.indexItemEditing = this.emailsAndDomains.findIndex(
        (value) => value === item,
      );
      this.input = item;
    },
    removeItem(item: string) {
      const indexItem = this.emailsAndDomains.findIndex(
        (value) => value === item,
      );

      this.emailsAndDomains.splice(indexItem, 1);
    },
  },
});
