var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "p",
        { staticClass: "mt-4 text-default-color text-sm family-regular" },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("MyProductsPage.email.modal.description")) + " "
          ),
        ]
      ),
      _c("div", { staticClass: "h-16 my-4" }, [
        _c(
          "div",
          [
            _c(
              "label",
              { staticClass: "text-label", attrs: { for: "input" } },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("MyProductsPage.email.modal.emailLabel")) +
                    " ou " +
                    _vm._s(_vm.$t("MyProductsPage.email.modal.domainLabel")) +
                    " "
                ),
              ]
            ),
            _c(
              "ValidationObserver",
              { ref: "form", attrs: { slim: "" } },
              [
                _c("input-validation", {
                  attrs: {
                    name: "domain/email",
                    rules: `required|${
                      _vm.input.includes("@") ? "email" : "domain"
                    }`,
                    mode: "passive",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "flex gap-2" },
                            [
                              _c("InputText", {
                                staticClass: "w-full",
                                class: { "p-invalid": errors[0] },
                                attrs: {
                                  id: "input",
                                  placeholder: _vm.$t(
                                    "MyProductsPage.email.modal.placeholder"
                                  ),
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.updateEmailOrDomain.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.input,
                                  callback: function ($$v) {
                                    _vm.input = $$v
                                  },
                                  expression: "input",
                                },
                              }),
                              _c("Button", {
                                staticClass: "outlined mr-3",
                                attrs: {
                                  label:
                                    _vm.indexItemEditing !== undefined
                                      ? _vm.$t("edit")
                                      : _vm.$t(`add`),
                                  icon:
                                    _vm.indexItemEditing !== undefined
                                      ? ""
                                      : "icon-r-circle-plus",
                                },
                                on: { click: _vm.updateEmailOrDomain },
                              }),
                            ],
                            1
                          ),
                          _vm.emailsAndDomainsIncludeInput &&
                          !_vm.inputEqualEditValue
                            ? _c("span", { staticClass: "p-invalid" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "MyProductsPage.email.modal.alredyExistsError"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("hr"),
      _c("div", [
        _c("span", { staticClass: "pl-3 mr-32 family-semi-bold text-label" }, [
          _vm._v(" " + _vm._s(_vm.$t("MyProductsPage.email.modal.type")) + " "),
        ]),
        _c("span", { staticClass: "family-semi-bold text-label" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("MyProductsPage.email.modal.address")) + " "
          ),
        ]),
      ]),
      _c("custom-table", {
        staticClass: "max-h-52 h-52 overflow-y-auto",
        attrs: { "columns-data": _vm.headerTable, data: _vm.emailsAndDomains },
        scopedSlots: _vm._u([
          {
            key: "columns",
            fn: function ({ item, column }) {
              return [
                column === "Tipo"
                  ? _c("td", { staticClass: "w-44" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.includes("@")
                              ? _vm.$t("MyProductsPage.email.modal.emailLabel")
                              : _vm.$t("MyProductsPage.email.modal.domainLabel")
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                column === "Endereco"
                  ? _c("td", [_vm._v(" " + _vm._s(item) + " ")])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "actions",
            fn: function ({ item }) {
              return [
                _c("div", { staticClass: "actions" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "outline-none border-none bg-transparent flex justify-center items-center",
                      on: {
                        click: function ($event) {
                          return _vm.editItem(item)
                        },
                      },
                    },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.top.html",
                            value: _vm.$t("edit"),
                            expression: "$t('edit')",
                            modifiers: { top: true, html: true },
                          },
                        ],
                        staticClass: "icon-r-edit",
                      }),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "outline-none border-none bg-transparent flex justify-center items-center",
                      on: {
                        click: function ($event) {
                          return _vm.removeItem(item)
                        },
                      },
                    },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.top.html",
                            value: _vm.$t("delete"),
                            expression: "$t('delete')",
                            modifiers: { top: true, html: true },
                          },
                        ],
                        staticClass: "icon-r-trash",
                      }),
                    ]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }