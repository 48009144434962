var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex justify-content-between align-items-center" },
    [
      _c("img", { attrs: { src: _vm.imageSrc, width: "135px" } }),
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "icon-bg rounded-full flex justify-center items-center",
        },
        [
          _c("i", {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.topleft.html",
                value: _vm.redirectText,
                expression: "redirectText",
                modifiers: { topleft: true, html: true },
              },
            ],
            staticClass: "icon-r-target-blank pointer",
            on: { click: _vm.redirectToRoit },
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "icon-bg rounded-full flex justify-center items-center",
        },
        [
          _c("i", {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.topleft.html",
                value: _vm.emailText,
                expression: "emailText",
                modifiers: { topleft: true, html: true },
              },
            ],
            staticClass: "icon-r-mailing pointer",
            on: {
              click: function ($event) {
                _vm.showModal = true
              },
            },
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "icon-bg rounded-full flex justify-center items-center",
        },
        [
          !_vm.hideIcon
            ? _c("i", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.topleft.html",
                    value: _vm.tooltipText,
                    expression: "tooltipText",
                    modifiers: { topleft: true, html: true },
                  },
                ],
                staticClass: "pointer icon-r-file-edit",
                on: {
                  click: function ($event) {
                    return _vm.$emit("showContract")
                  },
                },
              })
            : _vm._e(),
        ]
      ),
      _c("EmailModal", {
        attrs: { "show-modal": _vm.showModal },
        on: {
          "update:showModal": function ($event) {
            _vm.showModal = $event
          },
          "update:show-modal": function ($event) {
            _vm.showModal = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-grow-1 ml-3 mr-1" }, [
      _c("div", { staticStyle: { "border-top": "2px solid #0C5275" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }