
import CompanyPreferencesService from '@/common/services/CompanyPreferences';
import NotificationMixin from '@/mixins/NotificationMixin';
import mixins from 'vue-typed-mixins';
import EmailModalContent from './EmailModalContent.vue';

export default mixins(NotificationMixin).extend({
  name: 'EmailModal',
  components: {
    EmailModalContent,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      emailAndDomains: [] as string[],
      emailsNotAllowed: ['docs@roit.com.br', 'hom.docs@roit.com.br', 'sandbox.docs@roit.com.br'],
    };
  },
  computed: {
    modal: {
      get(): boolean {
        return this.showModal;
      },
      set(value: boolean): void {
        this.$emit('update:showModal', value);
      },
    },
  },
  methods: {
    async createUpdateCompanyPreferences(): Promise<void> {
      const { masterId } = this.$store.state.auth.userCompany;
      const payload = {
        clientId: masterId,
        emailInbox: this.emailAndDomains,
      };

      try {
        await CompanyPreferencesService.createUpdateCompanyPreferences(payload);
        this.modal = false;

        this.successToast({
          text: this.$t('MyProductsPage.email.modal.requestSucess') as string,
        });
      } catch {
        let email = '';
        const emailsNotAllowed = this.emailsNotAllowed
          .every((item) => {
            if (this.emailAndDomains.includes(item)) {
              email = item;
            }
            return true;
          });

        if (emailsNotAllowed) {
          this.errorToast({
            text: `${this.$t('MyProductsPage.email.modal.roitEmailError') as string}${email}`,
          });
        } else {
          this.errorToast({
            text: this.$t('MyProductsPage.email.modal.requestError') as string,
          });
        }
      }
    },
  },
});
