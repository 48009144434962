
import mixins from 'vue-typed-mixins';
import { mapGetters, mapMutations } from 'vuex';
import SectionHeader from '@/resources/my-products/components/SectionHeader.vue';
import ProductTable from '@/resources/my-products/components/product/ProductTable.vue';
import CancelProductModal from '@/resources/my-products/components/product/CancelProductModal.vue';
import { CancelProductInformation } from '@/types';
import NotificationMixin from '@/mixins/NotificationMixin';
import MyProductsService from '@/common/services/MyProductsService';
import { Deal } from '@/resources/my-products/types';

export default mixins(NotificationMixin).extend({
  name: 'MyProductsPage',
  components: {
    SectionHeader,
    ProductTable,
    CancelProductModal,
  },
  data: () => ({
    deals: [] as Deal[],
    searchModule: '' as string,
    contractLink: '',
    loading: false,
    product: {},
    cancelModalVisible: false,
    modalKey: 0,
    successfulRequestCancelProduct: false,
  }),
  async mounted() {
    if (this.token && this.userLoggedIn) {
      const [{ linkContract }] = await Promise.all(
        [
          MyProductsService.getContractLinkByMasterId(),
          this.findProducts(),
        ],
      );
      this.contractLink = linkContract;
    }
  },
  watch: {
    token(token: string): void {
      if (token && this.userLoggedIn) {
        this.findProducts();
      }
    },
    cancelModalVisible(value: boolean): void{
      if (!value) {
        this.modalKey += 1;
        this.successfulRequestCancelProduct = false;
      }
    },
  },
  created() {
    this.loading = true;
  },
  computed: {
    ...mapGetters({
      token: 'auth/token',
      userLoggedIn: 'auth/userLoggedIn',
    }),
    tableData() {
      const regexIgnoreCase = new RegExp(this.searchModule, 'i');
      const deals = this.searchModule
        ? this.deals.filter(
          (data) => regexIgnoreCase.test(data.product.name)
          || regexIgnoreCase.test(data.product.quantity),
        )
        : this.deals;

      return deals.map((deal) => deal.product);
    },
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    async findProducts(): Promise<void> {
      this.loading = true;
      this.deals = (await MyProductsService.getDeals()).data.data;
      this.loading = false;
    },
    getContractsAndAdditiveTerms(): void {
      if (this.contractLink) {
        window.open(this.buildContractUrl(this.contractLink), '_blank');
      } else {
        this.errorToast({
          title: this.$t('error') as string,
          text: this.$t('MyProductsPage.actions.contractsAndAdditiveTerms.msgError') as string,
        });
      }
    },
    buildContractUrl(url: string): string {
      const httpMatch = url.match(/[(https|http)://]/g);
      if (httpMatch) {
        return url;
      }

      return `https://${url}`;
    },
    showCancelProductModal(product: any): void{
      this.cancelModalVisible = true;
      this.product = product;
    },
    async cancelProduct(productData: CancelProductInformation): Promise<void> {
      try {
        this.handleLoading(true);
        await MyProductsService.deleteProduct(productData);
        this.successfulRequestCancelProduct = true;
      } catch (e) {
        this.errorToast({
          text: this.$t('MyProductsPage.cancelProduct.actions.msgError') as string,
        } as any);
      } finally {
        this.handleLoading(false);
      }
    },
  },
});
