
import Vue from 'vue';
import { mapMutations } from 'vuex';
import PageCard from '@/common/template/PageCard.vue';

export default Vue.extend({
  name: 'MyProducts',
  components: {
    PageCard,
  },
  data() {
    return {
      items: [
        {
          label: this.$t('MyProductsTemplate.myProducts'),
          to: {
            name: 'my-products-page',
          },
        },
      ],
    };
  },
  mounted() {
    this.handleRenderMenu(true);
    this.handleWithoutMenu(true);
  },
  methods: {
    ...mapMutations({
      handleRenderMenu: 'handleRenderMenu',
      handleWithoutMenu: 'handleWithoutMenu',
    }),
  },
});
