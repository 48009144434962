var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "my-products-page" },
    [
      _c(
        "div",
        {
          staticClass: "mt-4 d-flex justify-content-between align-items-center",
        },
        [
          _c("div", { staticClass: "grey300" }, [
            _vm._v(" " + _vm._s(_vm.$t("MyProductsPage.check")) + " "),
          ]),
          _c(
            "div",
            { staticClass: "col-md-3 p-fluid p-p-0" },
            [
              _c(
                "ValidationObserver",
                { ref: "form" },
                [
                  _c(
                    "input-validation",
                    {
                      attrs: {
                        rules: "min:3|max:40",
                        name: _vm.$t("search"),
                        slim: "",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "p-input-icon-left w-100" },
                        [
                          _c("i", {
                            staticClass: "icon-r-magnifier-tool grey100",
                          }),
                          _c("InputText", {
                            staticClass: "w-100",
                            staticStyle: { height: "40px" },
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("search"),
                            },
                            model: {
                              value: _vm.searchModule,
                              callback: function ($$v) {
                                _vm.searchModule = $$v
                              },
                              expression: "searchModule",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("SectionHeader", {
        staticClass: "mt-4",
        attrs: {
          "tooltip-text": _vm.$t("MyProductsPage.tooltip"),
          "redirect-text": _vm.$t("MyProductsPage.redirectText"),
          "email-text": _vm.$t("MyProductsPage.email.tooltip"),
          "image-src": require("@/common/assets/images/logo-roit-solutions-blue.svg"),
        },
        on: { showContract: _vm.getContractsAndAdditiveTerms },
      }),
      _c("ProductTable", {
        staticClass: "mt-4",
        attrs: {
          loading: _vm.loading,
          "product-name": "bank",
          "no-products": !_vm.deals.length && !_vm.searchModule,
          rows: _vm.tableData,
        },
        on: {
          "cancel-product": function ($event) {
            return _vm.showCancelProductModal($event)
          },
        },
      }),
      _c("CancelProductModal", {
        key: _vm.modalKey,
        attrs: {
          visible: _vm.cancelModalVisible,
          product: _vm.product,
          "successful-request": _vm.successfulRequestCancelProduct,
        },
        on: {
          "update:visible": function ($event) {
            _vm.cancelModalVisible = $event
          },
          "cancel-product": function ($event) {
            return _vm.cancelProduct($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }