
import mixins from 'vue-typed-mixins';
import SectionHeader from '@/resources/my-products/components/SectionHeader.vue';
import ResponsibleTable from '@/resources/my-products/components/responsible/ResponsibleTable.vue';
import RegisterResponsibleModal from '@/resources/onboarding/components/RegisterResponsibleModal.vue';
import Responsible from '@/mixins/ResponsibleMixin';

export default mixins(Responsible).extend({
  name: 'ManagerResponsible',
  components: {
    ResponsibleTable,
    RegisterResponsibleModal,
    SectionHeader,
  },
});
