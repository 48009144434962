var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Dialog",
    {
      staticClass: "text-125",
      style: { minWidth: "500px" },
      attrs: {
        header: _vm.$t(`MyProductsPage.email.modal.header.register`),
        modal: true,
        visible: _vm.modal,
        "content-style": { borderRadius: "10px" },
      },
      on: {
        "update:visible": function ($event) {
          _vm.modal = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "mt-3 mb-3 text-right" },
                [
                  _c("Button", {
                    staticClass: "outlined mr-3",
                    attrs: { label: _vm.$t(`cancel`) },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("update:showModal", false)
                      },
                    },
                  }),
                  _c("Button", {
                    staticClass: "white-button-icon",
                    attrs: { label: _vm.$t("save") },
                    on: {
                      click: function ($event) {
                        return _vm.createUpdateCompanyPreferences()
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("EmailModalContent", {
        ref: "emailModalContent",
        attrs: { "email-and-domains": _vm.emailAndDomains },
        on: {
          "update:emailAndDomains": function ($event) {
            _vm.emailAndDomains = $event
          },
          "update:email-and-domains": function ($event) {
            _vm.emailAndDomains = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }